@config "../../tailwind.config.ts";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tokens */

@layer base {
  :root {
    /* Existing Variables */
    --text: hsl(0, 100%, 0%);
    --text-placeholder: hsl(0, 0%, 50%);
    --background: hsl(0, 100%, 100%);
    --subtle-background: hsl(225, 25%, 95%);
    --button-text-hover: hsl(0, 0%, 100%);
    --button-bg-hover: rgba(0, 0, 0, 1);
    --icon: hsl(200, 75%, 65%);
    --heading: var(--text);
    --heading-background: linear-gradient(
      45deg,
      #f83a3a 10%,
      #f13dd4 50%,
      #7000ff 90%
    );
    --error: hsl(340, 95%, 50%);
    --input-border: #ccc;
    --focus-border: #0000ff;

    --screen-height: 100vh;
    --topbar-height: 4rem;
    --sidebar-width-sm: 16rem;
    --sidebar-width-md: 16rem;
    --sidebar-width-lg: 24rem;
    --sidebar-width-xl: 32rem;
    /* Shadcn UI */
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    /* Sidebar */
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;

    /* Button specific colors */
    --button-primary: hsl(0, 93%, 60%);
    --button-primary-foreground: hsl(0, 0%, 100%);
    --button-primary-hover: hsl(0, 93%, 50%);
    --button-primary-active: hsl(0, 93%, 40%);

    --button-cta: hsl(270, 100%, 50%);
    --button-cta-foreground: hsl(0, 0%, 100%);
    --button-cta-hover: hsl(270, 100%, 40%);
    --button-cta-active: hsl(270, 100%, 30%);

    /* FeatureCard Specific Variables - Light Mode */
    --feature-card-bg: hsl(0, 0%, 100%); /* White */
    --feature-card-border: hsl(214.3, 31.8%, 91.4%);
    --feature-card-title: hsl(0, 100%, 0%); /* Black */
    --feature-card-description: hsl(0, 0%, 50%);
    --feature-card-gradient: linear-gradient(
      135deg,
      #f83a3a 10%,
      #f13dd4 50%,
      #7000ff 90%
    );
    --feature-card-icon: hsl(255, 100%, 100%); /* White Icon */
  }

  :root[class~="dark"] {
    --text: hsl(225, 25%, 95%);
    --text-placeholder: hsl(225, 25%, 65%);
    --background: hsl(210, 30%, 8%);
    --subtle-background: hsl(0, 0%, 3%);
    --button-text-hover: hsl(210, 22%, 20%);
    --button-bg-hover: rgba(255, 255, 255, 1);
    --icon: hsl(200, 75%, 65%);
    --heading: var(--text);
    --heading-background: linear-gradient(
      45deg,
      hsl(160, 100%, 40%) 10%,
      #1a85ff 50%,
      #ff4b2b 90%
    );
    --error: hsl(340, 95%, 50%);
    --input-border: #666;
    --focus-border: #0000ff;

    /* Shadcn */
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;

    /* Sidebar */
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;

    /* Button specific colors for dark mode */
    --button-primary: hsl(0, 93%, 40%);
    --button-primary-foreground: hsl(0, 0%, 100%);
    --button-primary-hover: hsl(0, 93%, 50%);
    --button-primary-active: hsl(0, 93%, 60%);

    --button-cta: hsl(270, 100%, 30%);
    --button-cta-foreground: hsl(0, 0%, 100%);
    --button-cta-hover: hsl(270, 100%, 40%);
    --button-cta-active: hsl(270, 100%, 50%);

    /* FeatureCard Specific Variables - Dark Mode */
    --feature-card-bg: hsl(210, 30%, 8%);
    --feature-card-border: hsl(217.2, 32.6%, 17.5%);
    --feature-card-title: hsl(225, 25%, 95%);
    --feature-card-description: hsl(225, 25%, 65%);
    --feature-card-gradient: linear-gradient(
      135deg,
      hsl(160, 100%, 40%) 10%,
      #1a85ff 50%,
      #ff4b2b 90%
    );
    --feature-card-icon: hsl(255, 100%, 100%); /* White Icon */
  }

  @supports (height: 100svh) {
    :root {
      --screen-height: 100svh;
    }
  }

  * {
    font-variant-ligatures: none;
    @apply border-border;
  }

  :root,
  html,
  body,
  #__next {
    @apply h-full;
  }

  body {
    @apply font-sans antialiased bg-subtle-background text-foreground min-h-screen;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }

  html {
    scroll-padding-top: 10rem;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  h1 {
    @apply text-3xl font-bold;
  }

  h2 {
    @apply text-2xl font-bold;
  }

  h3 {
    @apply text-xl font-bold;
  }

  h4 {
    @apply text-lg font-bold;
  }

  p {
    @apply text-md;
  }

  img {
    @apply h-auto max-w-full;
  }

  /* svg {
    fill: currentColor;
  } */

  hr {
    margin: 2rem auto;
  }

  .text-muted {
    color: var(--text-placeholder);
  }

  .border-feature-card-border {
    border-color: var(--feature-card-border);
  }

  .bg-feature-card-bg {
    background-color: var(--feature-card-bg);
  }

  .text-feature-card-title {
    color: var(--feature-card-title);
  }

  .text-feature-card-description {
    color: var(--feature-card-description);
  }

  .text-feature-card-icon {
    color: var(--feature-card-icon);
  }

  .bg-feature-card-gradient {
    background-image: var(--feature-card-gradient);
  }

  /* Additional Custom Styles */

  .feature-card-icon-wrapper {
    position: relative;
  }

  .feature-card-icon-overlay {
    position: absolute;
    inset: 0;
    background-image: var(--feature-card-gradient);
    border-radius: 9999px; /* Full rounding */
    opacity: 0.1;
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer components {
  /* This correctly styles the minimal progress bar */
  #nprogress {
    @apply z-50 pointer-events-none;
  }

  #nprogress .bar {
    @apply bg-[slateblue] fixed z-50 top-0 left-0 w-full h-0.5;
  }

  .primary-input {
    @apply w-full max-w-full min-w-full py-4 pl-4 pr-10 text-text border-none rounded-md text-md placeholder-slate-500 bg-slate-100;
  }

  .word-break {
    word-break: break-word;
  }

  .grid-cols-sidebar-layout {
    grid-template-columns: 200px auto;
  }
}

.virtuoso-grid-list {
  @apply grid grid-cols-2 gap-4;
}

@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 5s linear infinite;
}

.highlighted {
  @apply bg-yellow-200;
}

.grid-thing {
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-auto-rows: 1fr;
  row-gap: 1rem;
}

.centered-wrapper {
  @apply flex justify-center items-center h-[50vh];
}

.error-wrapper {
  @apply flex flex-col justify-center items-center h-[50vh];
}

.error-message {
  @apply text-red-500;
}

.heading-container {
  @apply bg-background rounded-md inline-block py-2 px-4 shadow-md;
}

.gradient-text {
  @apply inline-block bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600;
}

.heading-gradient {
  font-weight: 700;
  background-image: linear-gradient(
    45deg,
    hsl(0, 100%, 65%) 10%,
    hsl(300, 100%, 65%) 50%,
    hsl(250, 100%, 65%) 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: font-size 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .heading-gradient {
    font-size: 1.5rem;
  }
}

.text-copy {
  @apply text-sm;
}

/* Customizing the overlay */
.react-joyride__overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay */
}

/* Customizing the spotlight */
.react-joyride__spotlight {
  border-radius: 50%; /* Circular spotlight */
  padding: 15px; /* Larger spotlight area */
}

/* Necessary for removing the blue outline */
.react-select__input {
  box-shadow: none !important;
}

/* Customizing the modal */

.message-modal {
  @apply bg-subtle-background;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

/* Customizing the overlay */

.message-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
